import React, { useEffect, useState, forwardRef } from 'react';
import axios from 'axios';

const AffiliateImages = forwardRef(({ onImageLoaded, onLinkReady }, ref) => {
  const [affiliateImages, setAffiliateImages] = useState([]);
  const [randomImage, setRandomImage] = useState(null);
  const [randomTagLine, setRandomTagLine] = useState('');
  const [error, setError] = useState(null);

  // List of tag lines to encourage user interaction
  const tagLines = [
    "CHECK IT OUT!",
    "LIMITED TIME!",
    "GRAB IT FAST!",
    "EXCITING DEAL!",
    "DON'T MISS OUT!",
    "ACT NOW!",
    "TOP PICK!",
    "BEST CHOICE!",
    "SEE MORE!",
    "TRENDING NOW!",
    "MUST-HAVE!",
    "HOT ITEM!",
    "NEW ARRIVAL!",
    "JUST IN!",
    "HURRY UP!",
    "COOL PICK!",
    "LATEST FIND!",
    "CHECK THIS!",
    "AMAZING!",
    "SPECIAL PICK!",
  ];

  const fetchAffiliateImages = async () => {
    try {
      const response = await axios.get('/api/v1/affiliate_images');
      setAffiliateImages(response.data);
    } catch (err) {
      console.error('Error fetching affiliate images:', err);
      setError('Failed to load affiliate images.');
    }
  };

  useEffect(() => {
    fetchAffiliateImages();

    // Set a random tag line once when the component is initialized
    const randomIndex = Math.floor(Math.random() * tagLines.length);
    setRandomTagLine(tagLines[randomIndex]);
  }, []);

  useEffect(() => {
    if (affiliateImages.length > 0) {
      const randomIndex = Math.floor(Math.random() * affiliateImages.length);
      setRandomImage(affiliateImages[randomIndex]);
    }
  }, [affiliateImages]);

  useEffect(() => {
    if (randomImage && onLinkReady) {
      onLinkReady(randomImage.link);
    }
  }, [randomImage, onLinkReady]);

  if (error) return <p>{error}</p>;
  if (!randomImage) return null;

  return (
    <div ref={ref} style={{ position: 'relative', textAlign: 'center', margin: '20px 0' }}>
      <a href={randomImage.link} target="_blank" rel="noopener noreferrer">
        <img
          src={randomImage.image_url}
          alt="Affiliate"
          onLoad={onImageLoaded} // Notify when the image has loaded
          style={{
            maxWidth: '100%',
            width: 'auto',
            height: 'auto',
            borderRadius: '8px',
            margin: '10px auto',
            display: 'block',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        />
        {/* Banner overlay */}
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            backgroundColor: 'rgba(255, 0, 0, 0.7)',
            color: 'white',
            fontWeight: 'bold',
            padding: '5px 15px',
            transform: 'rotate(-45deg)',
            zIndex: 1,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          {randomTagLine}
        </div>
      </a>
      <p style={{ fontWeight: 'bold', marginTop: '10px' }}>{randomImage.caption}</p>
    </div>
  );
});

export default AffiliateImages;

