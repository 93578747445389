// App.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Slate, Editable, withReact } from 'slate-react';
import { createEditor } from 'slate';
import AffiliateImages from './components/AffiliateImages';
import './App.css'; // Ensure CSS is imported

axios.defaults.baseURL = '/';
const baseURL = 'http://aimnewsbreak.com';

const App = () => {
  const [entries, setEntries] = useState([]);
  const [hasScrolledToEntry, setHasScrolledToEntry] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [editors, setEditors] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageWidths, setImageWidths] = useState({});
  const [J, setJ] = useState(false);
  const imageRefs = useRef({});
  const previousWidths = useRef({});
  const containerRef = useRef(null);
  const previousScrollTopRef = useRef(0); 
  const entryRefs = useRef({});
  const affiliateImageRefs = useRef({});
  const affiliateLinks = useRef({});
  const bottomLineRef = useRef(null);
  const MAX_RECORDS = 10; 
  const SHOW_VIDEO = false;

  const [showTopLoading, setShowTopLoading] = useState(false);
  const [showBottomLoading, setShowBottomLoading] = useState(false);
  const showTopLoadingRef = useRef(showTopLoading);
  const showBottomLoadingRef = useRef(showBottomLoading);

  // Store secret zuluTime here if provided initially
  const secretZuluTimeRef = useRef(null);

  useEffect(() => {
    showTopLoadingRef.current = showTopLoading;
  }, [showTopLoading]);

  useEffect(() => {
    showBottomLoadingRef.current = showBottomLoading;
  }, [showBottomLoading]);

  // Handle initial zuluTime if present
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const zuluTimeParam = queryParams.get('zuluTime');

    if (zuluTimeParam) {
      // If we have a zuluTime, store it secretly
      secretZuluTimeRef.current = zuluTimeParam;

      // Remove it from the URL so subsequent logic sees no zuluTime
      const url = new URL(window.location.href);
      url.searchParams.delete('zuluTime');
      navigate(url.pathname + url.search, { replace: true });
    } else {
      secretZuluTimeRef.current = null;
    }
    // Do not fetch here; fetchData will run due to useEffect below.
  }, [location.search, navigate]);

  const fetchData = useCallback(async () => {
    try {
      let zuluTime;
      if (secretZuluTimeRef.current) {
        const targetDate = new Date(secretZuluTimeRef.current);
        zuluTime = !isNaN(targetDate) ? targetDate.toISOString() : new Date().toISOString();
      } else {
        zuluTime = new Date().toISOString();
      }

      if (secretZuluTimeRef.current) {
        secretZuluTimeRef.current = null;
      }

      const params = {
        limit: MAX_RECORDS,
        zuluTime: zuluTime,
      };

      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;

      if (data.length === 0) {
        console.warn('No entries found for the specified zuluTime.');
      }

      const processedEntries = data.map((entry) => {
        const id = entry.id;
        const headline = entry.headline || '';
        const storyJson = entry.story;
        const story = storyJson ? JSON.parse(storyJson) : [];
        const createdAt = entry.created_at;
        const topImageId = entry.top_image_id;
        const video1 = entry.video_1;
        const topImageUrl = topImageId
          ? `/api/v1/images/${topImageId}/download`
          : null;

        return {
          id,
          headline,
          story,
          createdAt,
          topImage: topImageUrl,
          video1,
        };
      });

      setEntries(processedEntries);
      setEditors(processedEntries.map(() => withReact(createEditor())));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [MAX_RECORDS]);

  // Trigger initial fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (hasScrolledToEntry || entries.length === 0) {
      return;
    }

    // No scroll repositioning code. Just set hasScrolledToEntry = true.
    setHasScrolledToEntry(true);

  }, [location.search, entries, hasScrolledToEntry]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchNewerEntries = async () => {
    if (entries.length === 0) return;

    const topCreatedAt = entries[0].createdAt;

    const params = {
      limit: MAX_RECORDS,
      zuluTime: topCreatedAt,
      newerThan: true,
    };

    try {
      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;

      if (data.length > 0) {
        const newEntries = data.map((entry) => {
          const id = entry.id;
          const headline = entry.headline || '';
          const storyJson = entry.story;
          const story = storyJson ? JSON.parse(storyJson) : [];
          const createdAt = entry.created_at;
          const topImageId = entry.top_image_id;
          const video1 = entry.video_1;
          const topImageUrl = topImageId
            ? `/api/v1/images/${topImageId}/download`
            : null;

          return {
            id,
            headline,
            story,
            createdAt,
            topImage: topImageUrl,
            video1,
          };
        });

        setEntries((prevEntries) => [...newEntries, ...prevEntries]);
        setEditors((prevEditors) => [
          ...newEntries.map(() => withReact(createEditor())),
          ...prevEditors,
        ]);
      } else {
        console.log('No newer entries found.');
      }
    } catch (error) {
      console.error('Error fetching newer entries:', error);
    }
  };

  const fetchOlderEntries = async () => {
    if (entries.length === 0) return;

    const bottomCreatedAt = entries[entries.length - 1].createdAt;

    const params = {
      limit: MAX_RECORDS,
      zuluTime: bottomCreatedAt,
      olderThan: true,
    };

    try {
      const response = await axios.get('/api/v1/blog_entries', { params });
      const data = response.data;

      if (data.length > 0) {
        const newEntries = data.map((entry) => {
          const id = entry.id;
          const headline = entry.headline || '';
          const storyJson = entry.story;
          const story = storyJson ? JSON.parse(storyJson) : [];
          const createdAt = entry.created_at;
          const topImageId = entry.top_image_id;
          const video1 = entry.video_1;
          const topImageUrl = topImageId
            ? `/api/v1/images/${topImageId}/download`
            : null;

          return {
            id,
            headline,
            story,
            createdAt,
            topImage: topImageUrl,
            video1,
          };
        });

        setEntries((prevEntries) => [...prevEntries, ...newEntries]);
        setEditors((prevEditors) => [
          ...prevEditors,
          ...newEntries.map(() => withReact(createEditor())),
        ]);
      } else {
        console.log('No older entries found.');
      }
    } catch (error) {
      console.error('Error fetching older entries:', error);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    const buffer = 30;

    const deltaY = scrollTop - previousScrollTopRef.current;
    previousScrollTopRef.current = scrollTop;

    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const handleWheel = (event) => {
    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const buffer = 30;

    const deltaY = event.deltaY;

    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const touchStartRef = useRef(0);

  const handleTouchStart = (event) => {
    touchStartRef.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const currentTouchY = event.touches[0].clientY;
    const deltaY = touchStartRef.current - currentTouchY;

    const container = containerRef.current;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    const buffer = 30;

    if (deltaY < 0 && scrollTop <= buffer) {
      if (!showTopLoadingRef.current) {
        setShowTopLoading(true);
        showTopLoadingRef.current = true;
        fetchNewerEntries().then(() => {
          setShowTopLoading(false);
          showTopLoadingRef.current = false;
        });
      }
    }

    if (deltaY > 0 && scrollTop + clientHeight >= scrollHeight - buffer) {
      if (!showBottomLoadingRef.current) {
        setShowBottomLoading(true);
        showBottomLoadingRef.current = true;
        fetchOlderEntries().then(() => {
          setShowBottomLoading(false);
          showBottomLoadingRef.current = false;
        });
      }
    }
  };

  const handleEntryClick = (entryId, event) => {
    const clickX = event.pageX;
    const clickY = event.pageY;

    const affiliateImageElement = affiliateImageRefs.current[entryId];
    if (!affiliateImageElement) {
      return;
    }

    const rect = affiliateImageElement.getBoundingClientRect();
    const rectLeft = rect.left + window.scrollX;
    const rectTop = rect.top + window.scrollY;
    const rectRight = rect.right + window.scrollX;
    const rectBottom = rect.bottom + window.scrollY;

    if (
      clickX >= rectLeft &&
      clickX <= rectRight &&
      clickY >= rectTop &&
      clickY <= rectBottom
    ) {
      const link = affiliateLinks.current[entryId];
      if (link) {
        window.open(link, '_blank');
      }
    }
  };

  const convertChildToSlate = (child, video1) => {
    if (typeof child.text === 'string') {
      if (child.link && typeof child.link === 'string') {
        let textNode = { text: child.text };

        if (child.bold) {
          textNode.bold = true;
        }
        if (child.italic) {
          textNode.italic = true;
        }

        return {
          type: 'link',
          url: child.link,
          children: [textNode],
        };
      }

      if (child.text.includes('{{VIDEO_1}}')) {
        if (SHOW_VIDEO && video1) {
          const videoId = video1.split('v=')[1];
          const embedUrl = `https://www.youtube.com/embed/${videoId}`;
          return {
            type: 'youtube-video',
            url: embedUrl,
            children: [{ text: '' }],
          };
        } else {
          child.text = child.text.replace('{{VIDEO_1}}', '');
          if (child.text.trim() === '') {
            return null;
          }
        }
      }

      let textNode = { text: child.text };

      if (child.type === 'text') {
        if (child.italic) {
          textNode.italic = true;
        }
        if (child.bold) {
          textNode.bold = true;
        }
      }

      return textNode;
    } else if (child.type === 'link') {
      return {
        type: 'link',
        url: child.url,
        children: child.children
          .map((linkChild) => convertChildToSlate(linkChild, video1))
          .filter(Boolean),
      };
    } else {
      return { text: 'Unsupported child type' };
    }
  };

  const convertToSlateStructure = (nodes, video1) => {
    return nodes
      .map((block) => {
        if (!block || !block.type) return null;

        switch (block.type) {
          case 'paragraph':
            const paragraphChildren = block.children
              .map((child) => convertChildToSlate(child, video1))
              .filter(Boolean);
            if (paragraphChildren.length === 0) return null;
            return {
              type: 'paragraph',
              children: paragraphChildren,
            };

          case 'heading':
            const headingChildren = block.children
              .map((child) => convertChildToSlate(child, video1))
              .filter(Boolean);
            if (headingChildren.length === 0) return null;
            return {
              type: 'heading',
              level: block.level || 1,
              children: headingChildren,
            };

          case 'list':
            const listItems = block.children
              .map((item) => {
                if (item.type === 'list-item') {
                  const listItemChildren = [];

                  item.children.forEach((child) => {
                    if (child.type === 'paragraph') {
                      const pChildren = child.children
                        .map((c) => convertChildToSlate(c, video1))
                        .filter(Boolean);
                      listItemChildren.push(...pChildren);
                    } else if (child.type === 'list') {
                      const nestedList = convertToSlateStructure([child], video1);
                      if (nestedList && nestedList.length > 0) {
                        listItemChildren.push(...nestedList);
                      }
                    } else {
                      const inlineNode = convertChildToSlate(child, video1);
                      if (inlineNode) listItemChildren.push(inlineNode);
                    }
                  });

                  if (listItemChildren.length === 0) return null;

                  return {
                    type: 'list-item',
                    children: listItemChildren,
                  };
                } else {
                  return null;
                }
              })
              .filter(Boolean);

            if (listItems.length === 0) return null;

            return {
              type: block.format === 'ordered' ? 'numbered-list' : 'bulleted-list',
              children: listItems,
            };

          case 'image':
            return {
              type: 'image',
              url: block.url,
              alt: block.alt || '',
              children: [{ text: '' }],
            };

          case 'youtube-video':
            if (SHOW_VIDEO) {
              return {
                type: 'youtube-video',
                url: block.url,
                children: [{ text: '' }],
              };
            } else {
              return null;
            }

          case 'link':
            const linkChildren = block.children
              .map((child) => convertChildToSlate(child, video1))
              .filter(Boolean);

            return {
              type: 'link',
              url: block.url,
              children: linkChildren,
            };

          case 'blockquote':
            const blockquoteChildren = convertToSlateStructure(block.children, video1);
            if (!blockquoteChildren || blockquoteChildren.length === 0) return null;
            return {
              type: 'blockquote',
              children: blockquoteChildren,
            };

          case 'table':
            const tableRows = block.children
              .map((row) => {
                if (row.type === 'table-row') {
                  const cells = row.children
                    .map((cell) => {
                      if (cell.type === 'table-cell') {
                        const cellContent = cell.children
                          .map((child) => convertChildToSlate(child, video1))
                          .filter(Boolean);
                        return {
                          type: 'table-cell',
                          children: cellContent.length > 0 ? cellContent : [{ text: '' }],
                        };
                      }
                      return null;
                    })
                    .filter(Boolean);

                  return {
                    type: 'table-row',
                    children: cells,
                  };
                }
                return null;
              })
              .filter(Boolean);

            if (tableRows.length === 0) return null;

            return {
              type: 'table',
              children: tableRows,
            };

          // Handle thematicBreak
          case 'thematicBreak':
            return {
              type: 'thematic-break',
              children: [{ text: '' }],
            };

          default:
            const fallbackChildren = (block.children || [])
              .map((child) => convertChildToSlate(child, video1))
              .filter(Boolean);

            if (fallbackChildren.length === 0) {
              return {
                type: 'paragraph',
                children: [{ text: 'Unsupported block type' }],
              };
            }

            return {
              type: 'paragraph',
              children: [{ text: 'Unsupported block type' }],
            };
        }
      })
      .filter(Boolean);
  };

  const renderElement = ({ attributes, children, element }) => {
    switch (element.type) {
      case 'heading':
        const Tag = `h${element.level}`;
        return (
          <Tag {...attributes} style={{ marginTop: 0 }}>
            {children}
          </Tag>
        );
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{ marginTop: 0 }}>
            {children}
          </ul>
        );
      case 'list-item':
        return (
          <li {...attributes} style={{ marginTop: 0 }}>
            {children}
          </li>
        );
      case 'blockquote':
        return (
          <div {...attributes} className="blockquote">
            {children}
          </div>
        );
      case 'link':
        return (
          <a
            {...attributes}
            href={element.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: 0 }}
          >
            {children}
          </a>
        );
      case 'image':
        return (
          <div
            {...attributes}
            style={{ textAlign: J ? 'center' : 'right', margin: '0 auto' }}
          >
            <img
              ref={(el) => {
                imageRefs.current[element.id] = el;

                if (el && el.offsetWidth !== previousWidths.current[element.id]) {
                  previousWidths.current[element.id] = el.offsetWidth;
                  setImageWidths((prevWidths) => ({
                    ...prevWidths,
                    [element.id]: el.naturalWidth,
                  }));
                }
              }}
              src={element.url ? `${baseURL}${element.url}` : null}
              alt={element.alt}
              style={{
                width: `${imageWidths[element.id] || 'auto'}px`,
                height: 'auto',
                maxHeight: '400px',
                objectFit: 'contain',
                float: J ? 'none' : 'right',
                marginLeft: J ? 'auto' : '20px',
                marginRight: J ? 'auto' : '0',
                display: 'block',
              }}
            />
            {children}
          </div>
        );
      case 'youtube-video':
        const maxVideoWidth = Math.min(560, windowWidth - 40);
        const videoAspectRatio = 315 / 560;
        const videoHeight = maxVideoWidth * videoAspectRatio;

        return (
          <div
            {...attributes}
            style={{ margin: '20px 0', textAlign: 'center' }}
          >
            <iframe
              width={maxVideoWidth}
              height={videoHeight}
              src={element.url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {children}
          </div>
        );
      case 'paragraph':
        return (
          <p {...attributes} style={{ marginTop: 0 }}>
            {children}
          </p>
        );
      case 'table':
        return (
          <table {...attributes} style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>{children}</tbody>
          </table>
        );
      case 'table-row':
        return <tr {...attributes}>{children}</tr>;
      case 'table-cell':
        return (
          <td
            {...attributes}
            style={{
              border: '1px solid #ddd',
              padding: '8px',
              textAlign: 'left',
            }}
          >
            {children}
          </td>
        );
      case 'thematic-break':
        return (
          <hr
            {...attributes}
            style={{
              margin: '20px 0',
              border: 'none',
              borderTop: '1px solid #ccc',
            }}
          />
        );
      default:
        return (
          <p {...attributes} style={{ marginTop: 0 }}>
            {children}
          </p>
        );
    }
  };

  const renderLeaf = ({ attributes, children, leaf }) => {
    let style = {};

    if (leaf.bold) {
      style.fontWeight = 'bold';
    }
    if (leaf.italic) {
      style.fontStyle = 'italic';
    }

    return (
      <span {...attributes} style={style}>
        {children}
      </span>
    );
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      onWheel={handleWheel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      style={{
        position: 'relative',
        overflowY: 'auto',
        height: '100vh',
        overscrollBehavior: 'auto',
      }}
    >
      {showTopLoading && (
        <div className="loading-overlay top">
          Loading newer entries...
        </div>
      )}
      {showBottomLoading && (
        <div className="loading-overlay bottom">
          Loading older entries...
        </div>
      )}

      <ul>
        {entries.map((entry, index) => {
          const initialValue = [
            ...convertToSlateStructure(entry.story, entry.video1),
          ];

          const isNarrow = windowWidth < 600;

          const affiliateImageStyle = {
            width: isNarrow ? '100%' : '30%',
            float: isNarrow ? 'none' : 'right',
            marginLeft: isNarrow ? '0' : '15px',
            marginBottom: isNarrow ? '15px' : '0',
            boxSizing: 'border-box',
          };

          const affiliateImageRef = (el) => {
            affiliateImageRefs.current[entry.id] = el;
          };

          const handleLinkReady = (link) => {
            affiliateLinks.current[entry.id] = link;
          };

          return (
            <li
              ref={(el) => (entryRefs.current[`entry-${entry.id}`] = el)}
              id={`entry-${entry.id}`}
              key={entry.id || index}
              style={{
                marginBottom: '20px',
                padding: '10px',
                boxSizing: 'border-box',
                position: 'relative',
              }}
              onClick={(event) => handleEntryClick(entry.id, event)}
            >
              <div style={affiliateImageStyle}>
                <AffiliateImages
                  ref={affiliateImageRef}
                  onLinkReady={handleLinkReady}
                />
              </div>

              <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                {entry.createdAt}
              </div>

              <div>
                <Slate editor={editors[index]} initialValue={initialValue}>
                  <Editable
                    readOnly
                    style={{
                      fontSize: '16px',
                      lineHeight: '1.5',
                      marginTop: 0,
                      paddingTop: 0,
                    }}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                  />
                </Slate>
              </div>

              <div style={{ clear: 'both' }}></div>

              {index < entries.length - 1 && (
                <div
                  style={{
                    borderTop: '5px solid red',
                    marginTop: '20px',
                    clear: 'both',
                  }}
                ></div>
              )}
            </li>
          );
        })}
      </ul>

      <div
        ref={bottomLineRef}
        style={{ borderTop: '5px solid red', marginTop: '20px' }}
      ></div>
    </div>
  );
};

export default App;
